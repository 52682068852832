<template>
  <div class="container">
    <a-page-header :title="pageTitle" />

    <div class="main-content">
      <div class="body">
        <div class="area-box">
          <div class="item-box">
            <div class="title-box">
              <span class="title">省会</span>
              <a-button type="link" @click="handleModalAddOpen(1)">
                新增省会
              </a-button>
            </div>
            <div class="lists">
              <div
                class="item"
                :class="{ active: provinceIndex === index }"
                v-for="(item, index) in lists"
                :key="item.id"
                @click="provinceClick(index)"
              >
                <span class="text">{{ item.name }}</span>
                <div
                  class="btn-box hover-btn"
                  :class="{ 'active-btn': provinceIndex === index }"
                >
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleEditOpen({
                        id: item.id,
                        name: item.name,
                        index: index,
                        sort: 1
                      })
                    "
                  >
                    <my-icon type="iconicon_bianji" />
                  </a-button>
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleDel({
                        ok: item.children ? false : true,
                        id: item.id
                      })
                    "
                  >
                    <my-icon type="iconicon_shanchu" />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
          <div class="line-box">
            <a-icon type="caret-right" />
          </div>
          <div class="item-box">
            <div class="title-box">
              <span class="title">市/直辖市</span>
              <a-button type="link" @click="handleModalAddOpen(2)">
                新增市级
              </a-button>
            </div>
            <div class="lists" v-if="provinceIndex > -1">
              <div
                class="item"
                :class="{ active: cityIndex === index }"
                v-for="(item, index) in citys"
                :key="item.id"
                @click="cityClick(index)"
              >
                <span class="text">{{ item.name }}</span>
                <div
                  class="btn-box hover-btn"
                  :class="{ 'active-btn': cityIndex === index }"
                >
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleEditOpen({
                        id: item.id,
                        name: item.name,
                        index: index,
                        sort: 2
                      })
                    "
                  >
                    <my-icon type="iconicon_bianji" />
                  </a-button>
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleDel({
                        ok: item.children ? false : true,
                        id: item.id
                      })
                    "
                  >
                    <my-icon type="iconicon_shanchu" />
                  </a-button>
                </div>
              </div>
            </div>
            <div
              class="default-text"
              v-if="provinceIndex === -1 || !citys.length"
            >
              <span class="text">请选择省会/或当前列表为空</span>
            </div>
          </div>
          <div class="line-box">
            <a-icon type="caret-right" />
          </div>
          <div class="item-box">
            <div class="title-box">
              <span class="title">区/县</span>
              <a-button type="link" @click="handleModalAddOpen(3)">
                新增区/县级
              </a-button>
            </div>
            <div class="lists" v-if="cityIndex > -1">
              <div
                class="item"
                :class="{ active: areaIndex === index }"
                v-for="(item, index) in areas"
                :key="item.id"
                @click="areaClick(index)"
              >
                <span class="text">{{ item.name }}</span>
                <div
                  class="btn-box hover-btn"
                  :class="{ 'active-btn': areaIndex === index }"
                >
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleEditOpen({
                        id: item.id,
                        name: item.name,
                        index: index,
                        sort: 3
                      })
                    "
                  >
                    <my-icon type="iconicon_bianji" />
                  </a-button>
                  <a-button
                    type="link"
                    ghost
                    @click.stop="
                      handleDel({
                        ok: item.children ? false : true,
                        id: item.id
                      })
                    "
                  >
                    <my-icon type="iconicon_shanchu" />
                  </a-button>
                </div>
              </div>
            </div>
            <div class="default-text" v-if="cityIndex === -1 || !areas.length">
              <span class="text">请选择市级/或当前列表为空</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      title="修改名称"
      :visible="modalEditVisible"
      :confirm-loading="confirmEditModalLoading"
      @ok="handleEditModalOk"
      @cancel="handleEditModalCancel"
    >
      <a-form-model
        ref="ruleModalEditForm"
        :model="modalEditForm"
        :rules="modalEditRules"
      >
        <a-form-model-item label="当前名称">
          <a-input v-model="modalEditFormOldName" disabled />
        </a-form-model-item>
        <a-form-model-item ref="name" label="新名称" prop="name">
          <a-input
            v-model="modalEditForm.name"
            placeholder="请输入新名称"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="新增"
      :visible="modalAddVisible"
      :confirm-loading="confirmModalAddLoading"
      @ok="handleModalAddOk"
      @cancel="handleModalAddCancel"
    >
      <a-form-model
        ref="ruleModalAddForm"
        :model="modalAddForm"
        :rules="modalAddRules"
      >
        <a-form-model-item ref="type" label="选择要新增的地区类型" prop="type">
          <a-select
            v-model="modalAddForm.type"
            placeholder="请选择类型"
            @change="modalAddTypeSelect"
          >
            <a-select-option
              v-for="item in addTypes"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          ref="parent_id"
          label="所属省会"
          prop="parent_id"
          v-if="modalAddForm.type === 2"
        >
          <a-select
            v-model="modalAddForm.parent_id"
            placeholder="请选择所属省会"
          >
            <a-select-option
              v-for="item in lists"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          ref="parent_id"
          label="所属区/县"
          prop="parent_id"
          v-if="modalAddForm.type === 3"
        >
          <a-cascader
            expand-trigger="hover"
            v-model="modalAddForm.parent_id"
            placeholder="请选择所属区/县"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            :options="provinces"
          />
        </a-form-model-item>

        <a-form-model-item ref="name" :label="modalAddNameLabel" prop="name">
          <a-input
            v-model="modalAddForm.name"
            :placeholder="`请输入${this.modalAddNameLabel}`"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2312221_lttsu7mo8x.js' // 在 iconfont.cn 上生成
})
const labels = {
  1: '省会名称',
  2: '市/直辖市名称',
  3: '区/县名称'
}
const parents = {
  1: '省会',
  2: '市/直辖市',
  3: '区/县'
}
let editSort = 0 // 当前操作的省市区序号
let editIndex = 0 // 当前操作的列表索引
export default {
  data() {
    return {
      lists: [],
      activeIndex: -1,
      confirmModalAddLoading: false,
      modalAddVisible: false,
      modalAddForm: {
        type: undefined,
        name: '',
        parent_id: undefined
      },
      modalAddRules: {
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        name: [
          {
            required: true,
            message: '',
            trigger: 'blur'
          }
        ],
        parent_id: [
          {
            required: true,
            message: '',
            trigger: 'blur'
          }
        ]
      },
      addTypes: [
        {
          value: 1,
          label: '省会'
        },
        {
          value: 2,
          label: '市级'
        },
        {
          value: 3,
          label: '区县'
        }
      ],
      modalAddNameLabel: '',
      modalAddParentLabel: '',
      provinces: [],
      provinceIndex: -1,
      cityIndex: -1,
      areaIndex: -1,
      citys: [],
      areas: [],
      modalEditVisible: false,
      confirmEditModalLoading: false,
      modalEditForm: {
        id: '',
        name: ''
      },
      modalEditRules: {
        name: [{ required: true, message: '请输入新名称', trigger: 'blur' }]
      },
      modalEditFormOldName: ''
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    MyIcon
  },
  created() {
    this.getArea()
  },
  methods: {
    async getArea() {
      this.lists = await this.$axios.getArea()
      this.setProvinces()
    },
    setProvinces() {
      // 删除省市区的区
      const provinces = JSON.parse(JSON.stringify(this.lists))
      provinces.forEach((e) => {
        if (e.children) {
          remove(e.children)
        }
      })

      function remove(arr) {
        arr.forEach((e) => {
          delete e.children
        })
      }
      this.provinces = provinces
    },
    modalAddTypeSelect(e) {
      // 选择要添加的区域类型
      this.modalAddForm.type = e
      this.modalAddNameLabel = labels[e]
      this.modalAddParentLabel = parents[e]
      if (e === 3) {
        this.modalAddForm.parent_id = undefined
      }
    },
    handleModalAddOpen(type) {
      // 打开新建
      editSort = type
      this.modalAddNameLabel = labels[type]
      this.modalAddParentLabel = parents[type]
      this.modalAddForm.type = type
      this.modalAddVisible = true
    },
    handleModalAddOk() {
      // 确定新建
      this.$refs.ruleModalAddForm.validate((valid) => {
        if (valid) {
          this.confirmModalAddLoading = true

          let parentIdValue = this.modalAddForm.parent_id
          if (parentIdValue === undefined) {
            parentIdValue = 0
          }

          const type = this.modalAddForm.type
          if (type === 3) {
            parentIdValue = parentIdValue[parentIdValue.length - 1]
          }

          const data = {
            type: type,
            name: this.modalAddForm.name,
            parent_id: parentIdValue
          }
          this.$axios.addArea(data).then((res) => {
            this.confirmModalAddLoading = false
            this.$message.success('操作成功')
            const item = res.data.data
            if (editSort === 1) {
              this.lists.push(item)
            } else if (editSort === 2) {
              this.citys.push(item)
            } else if (editSort === 3) {
              this.areas.push(item)
            }
            this.handleModalAddCancel()
          })
        } else {
          return false
        }
      })
    },
    handleModalAddEdit(e) {
      // 编辑
      this.modalAddForm.name = e.name
      this.modalAddForm.description = e.description
      this.modalVisible = true
    },
    handleModalAddCancel() {
      // 取消新建
      this.modalAddVisible = false
      this.$refs.ruleModalAddForm.resetFields()
    },
    provinceClick(i) {
      // 省点击事件
      this.provinceIndex = i
      const lists = this.lists[i].children || []
      this.citys = lists
      this.cityIndex = -1
      this.areaIndex = -1
      this.areas = []
    },
    cityClick(i) {
      // 市点击事件
      this.cityIndex = i
      const lists = this.lists[this.provinceIndex].children[i].children || []
      this.areas = lists
    },
    areaClick(i) {
      // 区点击事件
      this.areaIndex = i
    },
    handleEditOpen(e) {
      // 编辑
      editSort = e.sort
      editIndex = e.index
      this.modalEditForm.id = e.id
      this.modalEditFormOldName = e.name
      this.modalEditVisible = true
    },
    handleEditModalOk() {
      this.$axios.editArea(this.modalEditForm).then(() => {
        this.$message.success('修改成功')
        const i = editIndex
        if (editSort === 1) {
          const item = this.lists[i]
          item.name = this.modalEditForm.name
          this.lists.splice(i, 1, item)
        } else if (editSort === 2) {
          const item = this.citys[i]
          item.name = this.modalEditForm.name
          this.citys.splice(i, 1, item)
        } else if (editSort === 3) {
          const item = this.areas[i]
          item.name = this.modalEditForm.name
          this.areas.splice(i, 1, item)
        }
        this.handleEditModalCancel()
        this.getArea()
      })
    },
    handleEditModalCancel() {
      // 取消编辑
      this.modalEditFormOldName = ''
      this.$refs.ruleModalEditForm.resetFields()
      this.modalEditVisible = false
    },
    handleDel() {
      // 删除
      this.$message.info('开发中')
      // if (e.ok) {
      //   this.$confirm({
      //     title: '确定删除?',
      //     onOk: () => {
      //       const data = {
      //         id: e.id
      //       }
      //       this.$axios.removeArea(data).then(() => {
      //         this.$message.success('删除成功')
      //       })
      //     }
      //   })
      // } else {
      //   this.$message.error('请先删除下级')
      // }
    }
  }
}
</script>

<style scoped>
.body {
  width: 960px;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 32px;
}

.area-box {
  height: 100%;
  display: flex;
  border: 1px solid #d9d9d9;
}

.item-box {
  width: 252px;
}

.line-box {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.line-box .anticon {
  color: #d9d9d9;
  font-size: 22px;
}

.item-box .title-box {
  height: 54px;
  padding: 0 16px;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-box .title {
  font-weight: 500;
  color: #333333;
}

.lists .item {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0px 1px 0px 0px #d9d9d9;
}

.lists .item:hover {
  background-color: #e6f7ff;
}

.lists .item.active {
  background-color: #e6f7ff;
}

.btn-box {
  display: none;
}

.lists .item:hover .hover-btn {
  display: block;
}

.lists .item .active-btn {
  display: block;
}

.lists .ant-btn {
  padding: 0;
  color: #1890ff;
}

.lists .ant-btn:first-child {
  margin-right: 12px;
}

.default-text {
  height: calc(100% - 54px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-text .text {
  color: #8c8c8c;
}

.ant-select {
  width: 100%;
}
</style>
